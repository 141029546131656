import React from "react";
import "./styles/Header.css";
import {Link} from 'react-scroll'; 
//import { Link } from "react-router-dom";
import mylogo from '../imgs/logo.png';
const Header = () => {
  return (
    <div className="n-wrapper">
      <div className="n-left">
        <div className="n-name">
      
        <img src={mylogo} alt="mylogo" width= '7rem'
    height= '3rem'/>

        
        </div>
        {/* <span>toggle</span> */}
      </div>
      <div className="n-right">
        <div className="n-list">
          <ul style={{ listStyleType: "none" }}>

          <Link spy={true} to='Header' smooth={true} activeClass="activeClass">
            <li>Home</li>
            </Link>

            <Link spy={true} to='services' smooth={true} activeClass="activeClass">
            <li>Service</li>
            </Link>
           
            
            <Link spy={true} to='experience' smooth={true} activeClass="activeClass">
            <li>Experience</li>
            </Link>
           
            <Link spy={true} to='Work' smooth={true} activeClass="activeClass">
            <li>Portfolio</li>
            </Link>
             
          </ul>
        </div>
        <Link spy={true} to='Contact' smooth={true} activeClass="activeClass">
        <button className="button n-button">Contact</button>
            </Link>
        
      </div>
    </div>

    // <div className="header">
    //   <div className="header__logo">
    //     <h1>Logo here</h1>
    //   </div>

    //   <nav>
    //     <u1>

    //       <li>

    //       </li>
    //     </u1>
    //   </nav>
    // </div>
  );
};
export default Header;
